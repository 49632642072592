<template>
	<div class="newsDetail">
		<div class="detailPosition">
			<span>你的位置：</span>
			<router-link :to="{name:'Home'}">
				<span class="toHome" @click="changePage(1)">首页</span>
			</router-link>
			<span>></span>
			<router-link :to="{name:'news'}">
				<span class="toHome" @click="whiteData">新闻资讯</span>
			</router-link>
			<span>></span>
			<span>{{detailData.title}}</span>
		</div>
		<div class="detailTitle">
			<h2>{{detailData.title}}</h2>
			<p>{{detailData.createTime}}</p>
		</div>
		<div class="detailText"
			v-show="detailData.cid == 152 || detailData.cid == 151 || detailData.cid == 147 || detailData.cid == 146">
			<p v-for="(item,index) in detailData.NewsFontList" :key="index">{{item}}</p>
		</div>
		<div class="detailText" v-show="detailData.cid == 150">
			<div class="imgBox3" v-if="detailData.contentImages">
				<img :src="detailData.contentImages[0]" alt="">
			</div>
			<p v-for="(item,index) in detailData.NewsFontList" :key="index">{{item}}</p>
		</div>
		<div class="detailText" v-show="detailData.cid == 149">
			<p v-if="detailData.NewsFontList[0]">{{detailData.NewsFontList[0]}}</p>
			<p>{{detailData.NewsFontList[1]}}</p>
			<div class="imgBox1" v-if="detailData.contentImages">
				<img :src="detailData.contentImages[0]" alt="">
			</div>
			<p v-for="(item,index) in detailData.NewsFontList" :key="index" v-if="index > 1">{{item}}</p>
			<div class="imgBox2" v-if="detailData.contentImages">
				<img :src="detailData.contentImages[1]" alt="">
			</div>
		</div>
		<div class="detailText" v-show="detailData.cid == 148">
			<p v-for="(item,index) in detailData.NewsFontList" :key="index">{{item}}</p>
			<div class="imgBox4" v-if="detailData.contentImages">
				<img :src="detailData.contentImages[0]" alt="">
			</div>
			<div class="imgBox5" v-if="detailData.contentImages">
				<img :src="detailData.contentImages[1]" alt="">
			</div>
			<div class="imgBox6" v-if="detailData.contentImages">
				<img :src="detailData.contentImages[2]" alt="">
			</div>
		</div>
		<div class="changeButton" v-show="detailData.cid == 152 || detailData.cid == 148">
			<div class="frontPage">
				<Icon type="ios-arrow-back" />
				<div class="leftLine"></div>
				<p>暂无上一篇</p>
				<div>
					<img src="../../assets/img/news/暂无下一页.png" alt="">
				</div>
			</div>
			<div class="frontPage nextPage" @click="nextText(detailData.nextTitle,detailData.nextId)">
				<div>
					<img :src="detailData.nextPic" alt="">
				</div>
				<p :class="detailData.cid == 152 ? 'twofont' : ''">{{detailData.nextTitle}}</p>
				<div class="rightLine"></div>
				<Icon type="ios-arrow-forward" />
			</div>
		</div>
		<div class="changeButton" v-show="detailData.cid == 151 || detailData.cid == 150 || detailData.cid == 147">
			<div class="frontPage" @click="nextText(detailData.preTitle,detailData.preId)">
				<Icon type="ios-arrow-back" />
				<div class="leftLine"></div>
				<p class="twofont">{{detailData.preTitle}}</p>
				<div>
					<img :src="detailData.prePic" alt="">
				</div>
			</div>
			<div class="frontPage nextPage" @click="nextText(detailData.nextTitle,detailData.nextId)">
				<div>
					<img :src="detailData.nextPic" alt="">
				</div>
				<p class="twofont">{{detailData.nextTitle}}</p>
				<div class="rightLine"></div>
				<Icon type="ios-arrow-forward" />
			</div>
		</div>
		<div class="changeButton" v-show="detailData.cid == 149 || detailData.cid == 146">
			<div class="frontPage" @click="nextText(detailData.preTitle,detailData.preId)">
				<Icon type="ios-arrow-back" />
				<div class="leftLine"></div>
				<p :class="detailData.cid == 149 ? 'twofont' : ''">{{detailData.preTitle}}</p>
				<div>
					<img src="../../assets/img/news/file1.png" alt="">
				</div>
			</div>
			<div class="frontPage nextPage">
				<div>
					<img src="../../assets/img/news/暂无下一页.png" alt="">
				</div>
				<p>暂无下一篇</p>
				<div class="rightLine"></div>
				<Icon type="ios-arrow-forward" />
			</div>
		</div>
	</div>
</template>

<script>
	import api from '../../services/api.js'
	export default {
		props: {
			detailData: {
				type: Object,
				default: () => {}
			}
		},
		data() {
			return {
				data: {
					details: {},
				}
			}
		},
		updated() {
			// chrome
			document.body.scrollTop = 0
			// firefox
			document.documentElement.scrollTop = 0
			// safari
			window.pageYOffset = 0
		},
		methods: {
			whiteData() {
				this.$emit('toPage', false)
			},
			nextText(title, id) {
				this.detailData.newsTitle = title
				let prams = this.detailData.type + '/'
				let picList = []
				this.$axios({
					url: api.newsDetail + prams + id,
					method: 'get',
				}).then((data) => {
					if (data.data.news.contentImages) {
						data.data.news.contentImages.forEach((item, index) => {
							item = api.url + item
							picList.push(item)
						})
						data.data.news.contentImages = picList
					}
					data.data.news.pic = api.url + data.data.news.pic
					data.data.news.prePic = api.url + data.data.news.prePic
					data.data.news.nextPic = api.url + data.data.news.nextPic
					let params = data.data.news
					params["NewsFontList"] = data.data.news.content.split("<br>")
					this.detailData = params
				})
			},
			changePage(id) {
				this.$emit('pageid', id)
			}
		}
	}
</script>

<style scoped lang="scss">
	.newsDetail {
		width: 1903px;
		padding: 40px 0 0 378px;

		.detailPosition {
			// width: 630px;
			display: flex;
			align-items: center;
			font-size: 16px;
			margin-bottom: 57px;

			a {
				color: #959595 !important;
			}

			.toHome,
			span {
				color: #959595;
			}

			.toHome:hover {
				cursor: pointer;
				color: #0067C7 !important;
			}

			.toHome:active {
				cursor: pointer;
				color: #00396F !important;
			}

			>span:nth-of-type(2),
			>span:nth-of-type(3) {
				margin: 0 10px;
			}

			>span:last-of-type {
				color: #006DB5 !important;
			}
		}

		.detailTitle {
			width: 1170px;
			text-align: center;
			line-height: 36px;
			margin-bottom: 18px;

			h2 {
				font-size: 26px;
				color: #000000;
				font-weight: 500;
			}

			p {
				font-size: 14px;
				color: #959595;
				font-weight: 400;
			}
		}

		.detailText {
			width: 1170px;
			font-size: 16px;
			text-indent: 32px;
			color: #444444;
			font-weight: 400;
			line-height: 30px;

			p {
				margin-bottom: 30px;
			}

			div {
				width: 1170px;
				text-align: center;
			}

			.imgBox1 {
				margin-bottom: 30px;

				>img {
					width: 600px;
					height: 400px;
				}
			}

			.imgBox2 {
				>img {
					width: 699px;
					height: 392px;
				}
			}

			.imgBox3 {
				margin-bottom: 10px;

				>img {
					width: 514px;
					height: 292px;
				}
			}

			.imgBox4 {
				margin-bottom: 30px;

				img {
					width: 900;
					height: 675;
				}
			}

			.imgBox5 {
				margin-bottom: 30px;

				img {
					width: 900;
					height: 600;
				}
			}

			.imgBox6 {
				margin-bottom: 30px;

				img {
					width: 900;
					height: 599;
				}
			}
		}

		.changeButton {
			width: 1168px;
			display: flex;
			align-items: center;
			margin-bottom: 54px;

			.frontPage {
				width: 584px;
				height: 120px;
				display: flex;
				align-items: center;
				background-color: #F2F2F2;
				border-right: 1px solid #959595;
				cursor: pointer;

				i {
					padding: 0 28px;
				}

				p {
					width: 290px;
					height: 15px;
					line-height: 15px;
					font-size: 16px;
					padding-left: 28px;
				}

				.leftLine {
					width: 10px;
					height: 15px;
					border-left: 1px solid #959595;
				}

				img {
					width: 144px;
					height: 64px;
					margin-left: 38px;
				}

				.emptyBox {
					width: 182px;
					height: 15px;
				}
			}

			.frontPage:hover {
				color: white;
				background: linear-gradient(45deg, #0067C7, #00C0FF);

				p {
					border-color: white;
				}

				.leftLine,
				.rightLine {
					border-color: white;
				}
			}

			.nextPage {
				border: none;

				p {
					width: 290px;
					padding-left: 0;
					padding-right: 28px;
					margin-left: 50px;
					text-align: right;
				}

				.rightLine {
					width: 10px;
					height: 15px;
					border-right: 1px solid #959595;
				}
			}

			.twofont {
				padding-bottom: 50px;
				line-height: 25px !important;
			}
		}
	}
</style>
